* {
    font-family: "Poppins", cursive;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
}

#root {
    width: 100vw;
    height: 100vh;
    overflow-x: auto;
}

input:disabled {
    background: #EEE;
}

.main-container {
    min-height: calc(100vh - 135px);
    max-width: 1280px;
    margin: 0 auto;
    padding: 30px 0;
}

.custom-card {
    margin: 20px 0;
    /* padding: 5px; */
}

.custom-card__header {
    display: flex;
    align-items: center;
    background: #EEEFEE;
    width: 100%;
    border: 1px solid #D1D0D0;
    padding: 3px;
}

.custom-card__header svg {
    margin-right: 4px;
}

.custom-card__body {
    border: 1px solid #D1D0D0;
    border-top: 0;
    padding: 20px;
}